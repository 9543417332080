var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "search-card"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-center mt-2 mb-2"
  }, [_c('h1', {
    staticClass: "text-primary text-center"
  }, [_vm._v(" Search players, teams or tournaments ")])])], 1), _c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12",
      "lg": "6"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "What are you looking for?",
      "size": "lg",
      "type": "search"
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "height": "20px",
      "icon": "SearchIcon",
      "width": "20px"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end mr-1"
  }, [_vm._v(" Filters: ")]), _c('div', {
    staticClass: "d-flex flex-row flex-wrap"
  }, _vm._l(_vm.filtersOptions, function (filter) {
    return _c('b-form-checkbox', {
      key: filter.value,
      staticClass: "mr-2",
      attrs: {
        "value": filter.value
      },
      model: {
        value: _vm.searchFilters,
        callback: function callback($$v) {
          _vm.searchFilters = $$v;
        },
        expression: "searchFilters"
      }
    }, [_vm._v(" " + _vm._s(filter.text) + " ")]);
  }), 1)])], 1)], 1)], 1)], 1), !_vm.isLoading && (!_vm.searchResults || _vm.searchResults.length === 0) ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('feather-icon', {
    staticClass: "m-2",
    attrs: {
      "icon": "FrownIcon",
      "size": "50"
    }
  }), _vm._v(" No data matches you search criteria ")], 1)], 1) : _vm._e(), _vm.isLoading ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _vm._e(), !_vm.isLoading && _vm.searchResults && _vm.searchResults.length > 0 ? _c('b-row', {
    staticClass: "justify-content-start"
  }, _vm._l(_vm.searchResults, function (searchResultItem, index) {
    return _c('b-card-group', {
      key: index,
      staticClass: "col-md-6 col-lg-4 col-sm-12",
      attrs: {
        "deck": ""
      }
    }, [_c('home-search-result-card', {
      attrs: {
        "item": searchResultItem
      }
    })], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }