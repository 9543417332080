import useApi from '@/api/useApi'

export default function useHome() {
  const fetchSearchResults = async (filters, query) => {
    const { search } = useApi()

    const {
      success,
      data,
    } = await search.globalSearch(filters || [], query)

    return success ? data : []
  }

  return {
    fetchSearchResults,
  }
}
