<template>
  <div>
    <!-- Filter -->
    <b-row>
      <b-col>
        <b-card class="search-card">
          <b-row>
            <b-col class="text-center mt-2 mb-2">
              <h1 class="text-primary text-center">
                Search players, teams or tournaments
              </h1>
            </b-col>
          </b-row>

          <!-- Tournament search input   -->
          <b-row class="justify-content-center">
            <b-col
              class="mb-2"
              md="12"
              lg="6"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="searchTerm"
                  placeholder="What are you looking for?"
                  size="lg"
                  type="search"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    height="20px"
                    icon="SearchIcon"
                    width="20px"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- End Tournament search input   -->

          <!-- Entities filter   -->
          <b-row class="justify-content-center">
            <b-col class="d-flex justify-content-center">
              <div class="d-flex justify-content-end mr-1">
                Filters:
              </div>
              <div class="d-flex flex-row flex-wrap">
                <b-form-checkbox
                  v-for="filter in filtersOptions"
                  :key="filter.value"
                  v-model="searchFilters"
                  :value="filter.value"
                  class="mr-2"
                >
                  {{ filter.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <!-- End Entities filter   -->
        </b-card>
      </b-col>
    </b-row>
    <!-- End Filter -->

    <!-- Search results -->
    <b-row v-if="!isLoading && (!searchResults || searchResults.length === 0)">
      <b-col class="d-flex flex-column align-items-center">
        <feather-icon
          class="m-2"
          icon="FrownIcon"
          size="50"
        />
        No data matches you search criteria
      </b-col>
    </b-row>

    <b-row v-if="isLoading">
      <b-col class="d-flex flex-column align-items-center">
        <b-spinner label="Loading..." />
      </b-col>
    </b-row>
    <!-- End Search results -->

    <!-- Search results -->
    <b-row
      v-if="!isLoading && searchResults && searchResults.length > 0"
      class="justify-content-start"
    >
      <b-card-group
        v-for="(searchResultItem, index) in searchResults"
        :key="index"
        class="col-md-6 col-lg-4 col-sm-12"
        deck
      >
        <home-search-result-card :item="searchResultItem" />
      </b-card-group>
    </b-row>
    <!-- End Search results -->
  </div>
</template>

<script>

import { onMounted, ref, watch } from '@vue/composition-api'
import {
  BCard, BCardGroup, BCol, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BRow, BSpinner,
} from 'bootstrap-vue'
import HomeSearchResultCard from '@/views/home/HomeSearchResultCard.vue'
import useHome from '@/views/home/useHome'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

export default {
  components: {
    HomeSearchResultCard,
    BCard,
    BCol,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BSpinner,
    BCardGroup,
    BFormCheckbox,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const filtersOptions = [
      { text: 'Players', value: 'users' },
      { text: 'Teams', value: 'teams' },
      { text: 'Tournaments', value: 'tournaments' },
      { text: 'Organizers', value: 'organizers' },
    ]

    const isLoading = ref(false)
    const searchTerm = ref(route?.query?.q ? route.query.q : '')
    const searchResults = ref([])
    const searchFilters = ref(route?.query?.f ? JSON.parse(route?.query.f) : ['users', 'teams', 'organizers', 'tournaments'])

    const { fetchSearchResults } = useHome()
    // eslint-disable-next-line no-shadow
    const fetchData = async (searchFilters, searchTerm) => {
      isLoading.value = true

      searchResults.value = await fetchSearchResults(searchFilters.value, searchTerm.value)

      let routeQuery = {
        ...route?.query || null,
      }

      if (searchTerm.value) {
        routeQuery = {
          ...routeQuery,
          q: searchTerm.value,
        }
      } else {
        delete routeQuery.q
      }

      if (searchFilters.value) {
        routeQuery = {
          ...routeQuery,
          f: JSON.stringify(searchFilters.value),
        }
      } else {
        delete routeQuery.f
      }

      router.push({ query: routeQuery }).catch(() => {})

      isLoading.value = false
    }

    watch(searchTerm, newValue => {
      searchTerm.value = newValue

      fetchData(searchFilters, searchTerm)
    })

    watch(searchFilters, () => {
      fetchData(searchFilters, searchTerm)
    })

    onMounted(() => {
      if (searchTerm.value || searchFilters.value) {
        fetchData(searchFilters, searchTerm)
      }
    })

    return {
      searchTerm,
      searchResults,
      isLoading,
      filtersOptions,
      searchFilters,
    }
  },
}
</script>

<style>

</style>
