<template>
  <b-card
    class="card-profile p-2"
    no-body
  >
    <div class="profile-image-wrapper">
      <div class="card-img-top">
        <b-avatar
          :src="item?.avatar_image"
          size="114"
          variant="primary"
        />
      </div>
    </div>

    <h3 class="mt-1">
      {{ item?.name }}
    </h3>

    <h6 class="text-muted">
      {{ entityTypeDefinition(item?.type) }}
    </h6>

    <div class="">
      <b-button
        size="sm"
        variant="primary"
        @click="redirectToProfile"
      >
        More info
      </b-button>
    </div>

    <hr class="mb-2">

    <!-- list disciplines -->
    <div v-if="item?.type === 'user'">
      <b-avatar
        v-for="(discipline) in item?.disciplines"
        :key="discipline.id"
        :src="discipline.image || null"
        :text="!discipline.image ? discipline.title : null"
        class="mr-1"
        size="45"
        square
        variant="primary"
      />
    </div>

    <!-- Show organizer or team content -->
    <div
      v-if="item?.type === 'organizer' ||item?.type === 'team' "
      class="d-flex justify-content-between align-items-center"
    >
      <ul class="list-unstyled ml-2">
        <li
          v-for="tournament in item?.upcoming_tournaments"
          :key="tournament.id"
        >
          <b-link :to="{name: 'tournament-info', params: {id: tournament.id, game: tournament.discipline.slug}}">
            <h6>{{ tournament.name }}</h6>
          </b-link>
        </li>
      </ul>
    </div>
    <!-- End show organizer or team content -->

    <!-- Show tournament content -->
    <div
      v-if="item?.type === 'tournament'"
      class="d-flex justify-content-between align-items-center"
    >
      <b-row class="flex-grow-1 align-items-center">
        <b-col md="3">
          <b-avatar
            :key="14"
            :src="getDisciplineImage(item?.disciplines[0].id)"
            size="45"
            square
            variant="primary"
          />
        </b-col>
        <b-col
          class="text-left"
          md="9"
        >
          <div v-if="!item.entry_fee">
            <span>
              <b-badge
                variant="light-success"
              >
                NO ENTRY FREE
              </b-badge>
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- End show tournament content -->

  </b-card>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow,
} from 'bootstrap-vue'
import useHomeSearchResultCard from '@/views/home/useHomeSearchResultCard'
import { getDisciplineImage } from '@/views/common'

export default {
  components: {
    BBadge,
    BCard,
    BAvatar,
    BButton,
    BLink,
    BRow,
    BCol,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: { getDisciplineImage },
  setup(props) {
    const {
      redirectToProfile,
      entityTypeDefinition,
    } = useHomeSearchResultCard(props)

    return {
      redirectToProfile,
      entityTypeDefinition,
    }
  },

}
</script>

<style lang="scss">
.card-profile {
  min-height: 350px;
}
</style>
