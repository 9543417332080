// eslint-disable-next-line import/no-extraneous-dependencies
import { useRouter } from 'vue2-helpers/vue-router'

export default function useHomeSearchResultCard({ item }) {
  const router = useRouter()

  const redirectToProfile = () => {
    const { type, id, disciplines } = item

    console.log(type, id)

    switch (type) {
      case 'user':
        return router.push({ name: 'player-profile', params: { id } })
      case 'organizer':
        return router.push({ name: 'organizer-profile', params: { id } })
      case 'team':
        return router.push({ name: 'team-profile', params: { id } })
      case 'tournament':
        return router.push({ name: 'tournament-info', params: { id, game: disciplines[0]?.slug } })
      default:
        return null
    }
  }

  const entityTypeDefinition = type => {
    let entityType = null
    switch (type) {
      case 'tournament':
        entityType = 'Tournament'
        break
      case 'organizer':
        entityType = 'Tournament organizer'
        break
      case 'user':
        entityType = item?.country ? `Player from ${item?.country.title}` : 'Player'
        break
      case 'team':
        entityType = item?.country ? `Team from ${item?.country.title}` : 'Team'
        break
      default:
        entityType = ''
    }

    return entityType
  }

  return {
    redirectToProfile,
    entityTypeDefinition,
  }
}
