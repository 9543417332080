var render = function () {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7, _vm$item8, _vm$item9, _vm$item10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-profile p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "profile-image-wrapper"
  }, [_c('div', {
    staticClass: "card-img-top"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.avatar_image,
      "size": "114",
      "variant": "primary"
    }
  })], 1)]), _c('h3', {
    staticClass: "mt-1"
  }, [_vm._v(" " + _vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.name) + " ")]), _c('h6', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.entityTypeDefinition((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.type)) + " ")]), _c('div', {}, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.redirectToProfile
    }
  }, [_vm._v(" More info ")])], 1), _c('hr', {
    staticClass: "mb-2"
  }), ((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.type) === 'user' ? _c('div', _vm._l((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.disciplines, function (discipline) {
    return _c('b-avatar', {
      key: discipline.id,
      staticClass: "mr-1",
      attrs: {
        "src": discipline.image || null,
        "text": !discipline.image ? discipline.title : null,
        "size": "45",
        "square": "",
        "variant": "primary"
      }
    });
  }), 1) : _vm._e(), ((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.type) === 'organizer' || ((_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.type) === 'team' ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('ul', {
    staticClass: "list-unstyled ml-2"
  }, _vm._l((_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : _vm$item8.upcoming_tournaments, function (tournament) {
    return _c('li', {
      key: tournament.id
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'tournament-info',
          params: {
            id: tournament.id,
            game: tournament.discipline.slug
          }
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(tournament.name))])])], 1);
  }), 0)]) : _vm._e(), ((_vm$item9 = _vm.item) === null || _vm$item9 === void 0 ? void 0 : _vm$item9.type) === 'tournament' ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('b-row', {
    staticClass: "flex-grow-1 align-items-center"
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-avatar', {
    key: 14,
    attrs: {
      "src": _vm.getDisciplineImage((_vm$item10 = _vm.item) === null || _vm$item10 === void 0 ? void 0 : _vm$item10.disciplines[0].id),
      "size": "45",
      "square": "",
      "variant": "primary"
    }
  })], 1), _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "9"
    }
  }, [!_vm.item.entry_fee ? _c('div', [_c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" NO ENTRY FREE ")])], 1)]) : _vm._e()])], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }